import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Button, Container, Icon, Label } from 'semantic-ui-react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './about.less';

const About = ({ data }) => {
  const initialState = {
    addi: false,
    amber: false,
    anna: false,
    ashley: false,
    blake: false,
    joe: false,
    jon: false,
    jonp: false,
    philippa: false,
  };
  const [teamDetails, openTeamMember] = useState(initialState);
  const photos = {};
  data.TeamPhotos.nodes.forEach(element => {
    photos[element.name] = element.childImageSharp;
  });

  return (
    <Layout className="pricing" withContainer={false}>
      <SEO
        title="About Us"
        description="We're on a mission to create the best online Node.js courses and tutorials to help you solve hard problems fast."
      />

      <Container className="banner" fluid>
        <Container text>
          <h1>About Hey Node</h1>
          <p>
            A labor of love from <a href="https://osiolabs.com">Osio Labs</a> —
            Hey Node is Open Source Inside and Out. Our long-term vision is to
            change online learning for web development, making it more effective
            and accessible for everyone. This includes crafting content and
            tools to ease learning, and addressing barriers that deny people
            access to learning resources.
          </p>
          <p>
            For over 10 years, we've been publishing tutorials, conducting
            workshops, supporting events, contributing code and documentation,
            and working in a variety of open source community leadership roles.
            Our site <a href="https://drupalize.me">Drupalize.Me</a> is the
            world’s leading provider of Drupal training services.
          </p>
        </Container>
      </Container>

      <Container text>
        <div className="team">
          <h2 id="team">Meet the team at Hey Node</h2>
          <p>
            <a href="https://github.com/OsioLabs/emphandbook/blob/master/01who_we_are/02values.md">
              Our values
            </a>{' '}
            include doing great work, feeding creativity, spreading happiness,
            working together, building trust, and caring for our customers. Last
            but not least, we aim to empower people!
          </p>

          <div className="team-members">
            {/* Addi */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({ ...initialState, addi: !teamDetails.addi });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.addi ? 'open' : 'closed'
                }`}
              >
                {teamDetails.addi && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img fluid={photos['addi'].fluid} alt="Addison Berry" />
                </div>
                <div className="team-member--name">Addison Berry</div>
                <div className="team-member--title">CEO</div>
                <div className="team-member--meta">
                  <a href="https://twitter.com/add1sun" className="twitter">
                    <Icon name="twitter" />
                  </a>
                </div>
                <div className="team-member--bio">
                  <p>
                    Addi joined our sister company,{' '}
                    <a href="https://lullabot.com">Lullabot</a>, as a developer,
                    consultant, and trainer before becoming Product Manager and
                    then Director of Education for Drupalize.Me. In 2016, when
                    Drupalize.Me spun off into its own company, today’s Osio
                    Labs, she took over as CEO.
                  </p>

                  <p>
                    Addi was the Drupal community documentation team lead from
                    2008 to 2010. In 2009, she used a Knight Foundation grant,
                    which rewards innovative ideas with community benefit, to
                    improve Drupal documentation. A co-author for the O'Reilly
                    book Using Drupal, she was also named one of the{' '}
                    <a href="http://www.fastcompany.com/3017254/women-in-tech-2010/the-most-influential-women-in-technology-2010-the-evangelists">
                      Most Influential Women in Tech
                    </a>{' '}
                    in 2010 by Fast Company Magazine.
                  </p>

                  <p>
                    Addi’s love of open source technology now manifests itself
                    in her being a champion for its expansion. She credits the
                    commitment and passion of her colleagues as her daily
                    inspiration to always push herself to be better. When she’s
                    not working, she’s hanging out in nature or reading - and on
                    a good day doing both.
                  </p>
                </div>
              </div>
            </div>

            {/* Amber */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({ ...initialState, amber: !teamDetails.amber });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.amber ? 'open' : 'closed'
                }`}
              >
                {teamDetails.amber && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img fluid={photos['amber'].fluid} alt="Amber Matz" />
                </div>
                <div className="team-member--name">Amber Matz</div>
                <div className="team-member--title">Production Manager</div>
                <div className="team-member--meta">
                  <a
                    href="https://twitter.com/amberhimesmatz"
                    className="twitter"
                  >
                    <Icon name="twitter" />
                  </a>
                </div>
                <div className="team-member--bio">
                  <p>
                    A web developer since 1999, Amber is the production manager
                    for Osio Labs, and is the driving force behind the
                    ever-evolving content architecture. Amber still considers
                    the feedback on client work she received from her local
                    community as formative — and continues to get immense value
                    from the insights and experience of learning developers.
                  </p>

                  <p>
                    She’s presented at conferences, worked as a program team
                    volunteer, and is on the Drupal Documentation Initiative
                    team. She’s co-presented in-person workshops at various
                    events, helped organize the Pacific NW Drupal Summit, and
                    works hard to contribute however she can to the community.
                    She also regularly participates in local meetups in and
                    around her home city of Portland, OR.
                  </p>

                  <p>
                    When Amber’s not immersed in content wrangling, she plays
                    the accordion, crochets, reads, enjoys exploring the
                    outdoors, and loves figuring out electronic kits. She also
                    loves a good tulip festival.
                  </p>
                </div>
              </div>
            </div>

            {/* Anna */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({ ...initialState, anna: !teamDetails.anna });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.anna ? 'open' : 'closed'
                }`}
              >
                {teamDetails.anna && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img fluid={photos['anna'].fluid} alt="Anna Mykhailova" />
                </div>
                <div className="team-member--name">Anna Mykhailova</div>
                <div className="team-member--title">Node.js Trainer</div>
                <div className="team-member--meta">
                  <a
                    href="https://twitter.com/sunnyenotick"
                    className="twitter"
                  >
                    <Icon name="twitter" />
                  </a>
                </div>
                <div className="team-member--bio">
                  <p>
                    Anna is passionate about web development and has a thirst
                    for knowledge. She enjoys trying new technologies, and is
                    always seeking to improve her existing skills. Her
                    background is in Drupal, (she’s an Acquia Certified Grand
                    Master), and she brings real-world lessons learned from over
                    five years of agency experience to the training material she
                    produces. Anna has a deep understanding of Node’s internals,
                    and enjoys finding ways to translate that knowledge into
                    human-friendly examples and documentation.
                  </p>

                  <blockquote>
                    “For me, the most satisfying feeling is completing a task
                    that seemed impossible.”
                  </blockquote>
                </div>
              </div>
            </div>

            {/* Ashley */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({
                  ...initialState,
                  ashley: !teamDetails.ashley,
                });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.ashley ? 'open' : 'closed'
                }`}
              >
                {teamDetails.ashley && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img fluid={photos['ashley'].fluid} alt="Ashley Jones" />
                </div>
                <div className="team-member--name">Ashley Jones</div>
                <div className="team-member--title">Technical Support</div>
                <div className="team-member--meta"></div>
                <div className="team-member--bio">
                  <p>
                    After joining Osio Labs in July 2018, Ashley soon became our
                    full-time Account Management and People Support for Hey
                    Node, and Drupalize.Me, doing everything from financial
                    reporting to administrative tasks, and handling the
                    day-to-day operations of running an E-learning company like
                    a boss. When she’s not supporting both our team and our
                    customers, Ashley enjoys time with family and friends and
                    traveling to exotic destinations.
                  </p>
                  <p>
                    Ashley is deeply familiar with the community spirit that
                    drives open source. While living in Houston, TX, she
                    contributed to the film community by supporting peer
                    projects and volunteering on film sets for talent and crew.
                  </p>

                  <p>
                    She lives by a quote from Maya Angelou: “If you don’t like
                    something, change it. If you can’t change it, change your
                    attitude.”
                  </p>
                </div>
              </div>
            </div>

            {/* Blake */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({ ...initialState, blake: !teamDetails.blake });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.blake ? 'open' : 'closed'
                }`}
              >
                {teamDetails.blake && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img fluid={photos['blake'].fluid} alt="Amber Matz" />
                </div>
                <div className="team-member--name">Blake Hall</div>
                <div className="team-member--title">Senior Developer</div>
                <div className="team-member--meta">
                  <a href="https://twitter.com/blakehall" className="twitter">
                    <Icon name="twitter" />
                  </a>
                </div>
                <div className="team-member--bio">
                  <p>
                    Blake became a developer in 2003, holding to the widely held
                    maxim that the biggest predictor of aptitude for computer
                    programming is a deep comfort with meaninglessness.
                  </p>
                  <p>
                    Inspired by his experiences at{' '}
                    <a href="http://2014.jsconf.us/">JS.conf</a> and{' '}
                    <a href="http://nodeconf.com/">NodeConf</a>, Blake led
                    Wisconsin's first{' '}
                    <a href="http://nodeschool.io/wisconsin">NodeSchool</a>{' '}
                    event. He also organized the first Wisconsin Drupal camps
                    starting in 2008. Blake has done extensive work with Node.js
                    including helping build an agile QA tool for{' '}
                    <a href="https://lullabot.com">Lullabot</a>.
                  </p>
                  <p>
                    From Blake's{' '}
                    <a href="http://en.wikipedia.org/wiki/Paul_Bunyan">
                      Bunyan-esque beard
                    </a>
                    , his years as a cheese factory worker, his Packers season
                    tickets, and his log cabin in the woods, Blake comfortably
                    embraces several Wisconsin stereotypes. He’s happiest
                    playing golf, reading about golf, watching golf, and
                    thinking about golf. He also raises funds for diabetes and
                    loves cooking for his family.
                  </p>
                </div>
              </div>
            </div>

            {/* Joe */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({ ...initialState, joe: !teamDetails.joe });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.joe ? 'open' : 'closed'
                }`}
              >
                {teamDetails.joe && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img fluid={photos['joe'].fluid} alt="Joe Shindelar" />
                </div>
                <div className="team-member--name">Joe Shindelar</div>
                <div className="team-member--title">
                  Lead Developer / Trainer
                </div>
                <div className="team-member--meta">
                  <a href="https://twitter.com/eojthebrave" className="twitter">
                    <Icon name="twitter" />
                  </a>
                </div>
                <div className="team-member--bio">
                  <p>
                    Joe is a developer, art enthusiast, and snowboarder who’s
                    been building web applications and participating in
                    open-source development since 2006. He is part of the{' '}
                    <a href="https://www.drupal.org/governance/doc-working-group">
                      Drupal Documentation Working Group
                    </a>
                    , helping to improve guidelines, policies, and overall
                    quality of Drupal documentation. And loves to geek out on
                    improving open-source documentation, and open-source
                    community sustainability, whenever he gets the chance.
                  </p>
                  <p>
                    If he had a personal motto, it would be: “Go fast! Take
                    chances!” He also loves ice cream. Like - a lot.
                  </p>
                </div>
              </div>
            </div>

            {/* Jon Church */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({ ...initialState, jon: !teamDetails.jon });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.jon ? 'open' : 'closed'
                }`}
              >
                {teamDetails.jon && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img fluid={photos['jon-church'].fluid} alt="Jon Church" />
                </div>
                <div className="team-member--name">Jon Church</div>
                <div className="team-member--title">Node.js Specialist</div>
                <div className="team-member--meta">
                  <a href="https://twitter.com/jonchurch" className="twitter">
                    <Icon name="twitter" />
                  </a>
                </div>
                <div className="team-member--bio">
                  <p>
                    As the Node.js subject matter expert for Hey Node,{' '}
                    <a href="https://jonchurch.com/">Jon</a> considers himself
                    first and foremost a writer working in the tech industry. He
                    writes, researches, codes, and records courses that teach
                    people the infinite possibilities of Node.js. He’s also
                    super psyched to contribute to the project as an open source
                    citizen. A self-taught developer, Jon loves sharing what
                    he’s learned. Giving back to those who are where he was just
                    a few years ago is the most rewarding part of his job.
                  </p>
                  <p>
                    Before working on Hey Node (and repatriating to his home state
                    of Florida), Jon worked as a consulting developer living in
                    Chiang Mai, Thailand. His goal is to one day be fluent
                    enough in Thai to order his favorite noodle soup, Khanom
                    Jeen Nam Ngiaw, exactly how he likes it.
                  </p>
                  <p>
                    Outside of work, Jon loves cooking, especially for his
                    family. Before becoming a developer, he worked as a vegan
                    chef for the music streaming startup Grooveshark.
                  </p>
                </div>
              </div>
            </div>

            {/* Jon Peck */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({ ...initialState, jonp: !teamDetails.jonp });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.jonp ? 'open' : 'closed'
                }`}
              >
                {teamDetails.jonp && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img fluid={photos['jon-peck'].fluid} alt="Jon Peck" />
                </div>
                <div className="team-member--name">Jon Peck</div>
                <div className="team-member--title">Technical Reviewer</div>
                <div className="team-member--meta">
                  <a href="https://twitter.com/fluxsauce" className="twitter">
                    <Icon name="twitter" />
                  </a>
                </div>
                <div className="team-member--bio">
                  <p>
                    As Hey Node’s technical reviewer, Jon ensures the content is
                    accessible and fulfills the intent of the educational goals.
                    He's passionate about open-source software, including code
                    and project contributions within the Node.js and Drupal
                    communities. His presentations at open-source conferences
                    across North America have included topics such as site and
                    code quality auditing, performance optimization, and
                    architectural best practices. Jon can be found on the
                    internet as @fluxsauce.
                  </p>
                  <blockquote>
                    “My input as a reviewer should move the conversation forward
                    while giving space to the author for maintaining their own
                    voice.”
                  </blockquote>
                </div>
              </div>
            </div>

            {/* Philippa */}
            <div
              className="team-member"
              onClick={e => {
                openTeamMember({
                  ...initialState,
                  philippa: !teamDetails.philippa,
                });
              }}
            >
              <div
                className={`team-member--popout ${
                  teamDetails.philippa ? 'open' : 'closed'
                }`}
              >
                {teamDetails.philippa && (
                  <Label corner="right" color="red">
                    Close
                  </Label>
                )}
                <div className="team-member--photo">
                  <Img
                    fluid={photos['philippa'].fluid}
                    alt="Philippa Stasiuk"
                  />
                </div>
                <div className="team-member--name">Philippa Stasiuk</div>
                <div className="team-member--title">Marketing Coordinator</div>
                <div className="team-member--meta">
                  <a
                    href="https://twitter.com/P1ppa5"
                    className="twitter"
                  >
                    <Icon name="twitter" />
                  </a>
                </div>
                <div className="team-member--bio">
                  <p>
                    Philippa joined the Osio Labs team in the summer of 2018,
                    and has been juggling marketing and content strategy
                    endeavors ever since. She started out in marketing as a
                    copywriter for a digital agency in Copenhagen, Denmark.
                    Before that, she worked as a reporter for a historic weekly
                    newspaper in upstate New York. Since moving back to the
                    states with her family, Philippa has written, edited, and
                    provided content strategy for many websites and blogs, and
                    sometimes still writes freelance articles. As a formative
                    lesson in content strategy, the achievement she’s most proud
                    of is her{' '}
                    <a href="https://www.amazon.com/Wonderful-Whippet-Winifred-Weatherwax/dp/0999694707">
                      young adult mystery novel
                    </a>{' '}
                    about a prizewinning whippet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container className="more" text>
        <p>Learn more about us:</p>
        <ul>
          <li>
            Read the{' '}
            <a href="https://github.com/OsioLabs/emphandbook">
              Osio Labs employee handbook
            </a>
          </li>
          <li>
            Learn about{' '}
            <a href="https://osiolabs.com/blog/how-we-chose-new-market">
              how, and why, we chose Node.js
            </a>
          </li>
        </ul>
      </Container>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query About {
    TeamPhotos: allFile(filter: { relativePath: { glob: "team/*" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
